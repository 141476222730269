@charset "UTF-8";
@import "mixins";

header {
  @media screen and (min-width: 1110px) {
    padding-bottom: 60px;
  
  }
  #gnav {
    .en {
      margin-top: 30px;
      @media (min-width: 768px) {
        margin-top: 70px;
      }
      img {
        height: 18px;
        @media (min-width: 768px) {
          height: 22px;
          width: auto;
        }
        
      }
      &.active {
        opacity: .3;
      }
    }
  }
}
.module_info {
  .door {
    margin-top: 50px;
    margin-bottom: 30px;
    display: flex;
    padding-inline: 15px;
    @media (min-width: 768px) {
      margin-bottom: 50px;
      padding-inline: unset;
    }
    li {
      flex: 1;
      border-left: 1px solid #d4d4d4;
      &:last-child {
        border-right: 1px solid #d4d4d4;
      }
      a {
        display: block;
        padding: 20px 0 12px;
        transition: opacity .3s ease;
        @media (min-width: 768px) {
          padding: 30px 0 18px;
          &:hover {
            opacity: .3;
          }
        }
        img  {
          height: 20px;
          @media (min-width: 768px) {
            height: 26px;
          }
        }
      }
    }
  }
}